"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeAdSlotRefreshManager = exports.slotNames = void 0;
// ad slot names (FTA Article specific)
exports.slotNames = ['top', 'top-tablet', 'top-mobile', 'skins', 'mid', 'mid2', 'midrhr1', 'midrhr2', 'midrhr3', 'midrhr4', 'midrhr5', 'midrhr6', 'midrhr7', 'mid1-desktop', 'mid2-desktop', 'mid3-desktop', 'mid4-desktop', 'mid5-desktop', 'mid6-desktop', 'mid7-desktop', 'mid8-desktop', 'mid9-desktop', 'mid10-desktop', 'mid1-tablet', 'mid2-tablet', 'mid3-tablet', 'mid4-tablet', 'mid5-tablet', 'mid6-tablet', 'mid7-tablet', 'mid8-tablet', 'mid9-tablet', 'mid10-tablet', 'mid1-mobile', 'mid2-mobile', 'mid3-mobile', 'mid4-mobile', 'mid5-mobile', 'mid6-mobile', 'mid7-mobile', 'mid8-mobile', 'mid9-mobile', 'mid10-mobile', 'contentad', 'contentad-tablet', 'contentad-mobile', 'after-content', 'after-content-mobile', 'after-content-tablet', 'mid21-mobile'];
const refreshThreshold = 30000; // 30 seconds default threshold
const adSlotStates = {};
const visibleSlots = new Set();
const initializeAdSlotRefreshManager = (slotNames, refreshInterval = refreshThreshold) => {
    let userIsActive = false;
    // initialize state for each slot
    slotNames.forEach((slotName) => {
        adSlotStates[slotName] = {
            creativeIds: [],
            adServedTwice: false,
            refreshCount: 1,
        };
    });
    // refresh logic
    setInterval(() => {
        if (userIsActive) {
            visibleSlots.forEach((slotName) => {
                var _a, _b;
                const slotState = adSlotStates[slotName];
                if (!slotState.adServedTwice && ((_b = (_a = window.oAds) === null || _a === void 0 ? void 0 : _a.slots) === null || _b === void 0 ? void 0 : _b[slotName])) {
                    window.oAds.slots.refresh(slotName);
                    slotState.refreshCount++;
                }
            });
        }
        userIsActive = false;
    }, refreshInterval);
    document.addEventListener('oAds.slotExpand', (event) => {
        const slotName = event.detail.name;
        if (!slotNames.includes(slotName))
            return;
        const adCreativeId = window.oAds.slots[slotName].gpt.creativeId;
        const slotState = adSlotStates[slotName];
        // set targeting for slot refresh count
        window.oAds.slots[slotName].targeting.refreshCount = slotState.refreshCount;
        window.oAds.slots[slotName].setTargeting();
        // track creative IDs to avoid duplicate ads
        if (slotState.creativeIds.includes(adCreativeId)) {
            slotState.adServedTwice = true;
        }
        else {
            slotState.creativeIds.push(adCreativeId);
        }
    });
    // track user activity
    document.addEventListener('scroll', () => {
        userIsActive = true;
    });
    // using intersection observer to detect visible slots
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            const slotName = entry.target.getAttribute('data-o-ads-name');
            if (!slotName)
                return;
            if (entry.isIntersecting) {
                visibleSlots.add(slotName);
            }
            else {
                visibleSlots.delete(slotName);
            }
        });
    }, { threshold: 0.25 } // slot is considered visible when 25% of it is in the viewport
    );
    // observe all ad slots
    slotNames.forEach((slotName) => {
        const slotElement = document.querySelector(`[data-o-ads-name="${slotName}"]`);
        if (slotElement)
            observer.observe(slotElement);
    });
};
exports.initializeAdSlotRefreshManager = initializeAdSlotRefreshManager;
